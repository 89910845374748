<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Unser Weg der Preisfindung</h1>
      <p>
        Wir sind kein extraktives Unternehmen, sondern versuchen stattdessen, alles, was wir tun, regenerativ zu
        gestalten und das Land verbessern, auf dem wir leben und arbeiten.
      </p>
      <p>
        Unsere Kunden schätzen den Wert eines Produktes oder einer Dienstleistung, indem sie vollständig verstehen,
        woher es kommt und wie es entsteht. Je mehr wir alle wissen, desto besser können wir Entscheidungen treffen.
      </p>
      <p>
        Es gibt keinen festgelegten Preis für ein Produkt oder eine Dienstleistung. Als Kunde können Sie einen
        Preisregler verschieben und Ihren eigenen Preis festlegen. Wir akzeptieren Ihr Eindruck des Wertes. Es gibt
        einen Preisvorschlag und Sie können den Preis senken oder erhöhen. Je mehr Sie den Preis erhöhen, desto mehr
        können wir in den regenerativen Teil unserer Unternehmung investieren. Wenn Sie den Preis senken, verstehen wir
        Ihre Situation.
      </p>

      <YouTubeLink />
    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "PriceSliderDE",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
